import { useState, useEffect } from "react";
import vim from "../assets/img/vim-16-svgrepo-com.svg";
import react from "../assets/img/react-16-svgrepo-com.svg";
import java from "../assets/img/java-16-svgrepo-com.svg";
import python from "../assets/img/python-16-svgrepo-com.svg";
import c from "../assets/img/c-16-svgrepo-com.svg";
import js from "../assets/img/javascript-16-svgrepo-com.svg";
import html from "../assets/img/html-16-svgrepo-com.svg";
import docker from "../assets/img/docker-16-svgrepo-com.svg";
import vscode from "../assets/img/vscode-16-svgrepo-com.svg";
import npm from "../assets/img/npm-16-svgrepo-com.svg";
import json from "../assets/img/json-16-svgrepo-com.svg";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

export const Skills = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState('');
  const [delta, setDelta] = useState(300 - Math.random() * 100);
  const toRotate = ["Software Engineer", "Web Developer", "Fan of AI", "Grinder"];
  const period = 2000;

  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);
    return () => { clearInterval(ticker) };
  }, [text])

  const tick = () => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1);

    setText(updatedText);

    if (isDeleting) {
      setDelta(prevDelta => prevDelta / 2);
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
      setDelta(period);
    } else if (isDeleting && updatedText === '') {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      setDelta(500);
    } else {
    }
  }
  const fontSizeStyle = {
    fontSize: '5.5rem',
    marginBottom: '2.5rem'
  };

  const centeredContentStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    flexDirection: 'column',
    marginBottom: '-160px'
  };

  const marginTopStyle = {
    marginTop: '2rem',
    // marginBottom: '20px'
  };

  const sliderStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // height: '100vh',
    flexDirection: 'column',
    marginTop: '-5rem',
    marginBottom: '30px'
  };
  return (
    <section className="skill" id="skills">
      <div className="container">
        <div className="row">
          <div className="col-12" style={centeredContentStyle}>
            <h1 style={fontSizeStyle}>I am a <span className="wrap">{text}</span></h1>
            <h2 style={marginTopStyle}>Skills</h2>
            <p style={marginTopStyle}>In my journey through computer science, I began with Java, simultaneously diving into
              Data Structures and Algorithms. This foundational knowledge paved the way for my internship at Jisuanke,
              where I extensively used PHP and began mastering Laravel, a powerful and renowned framework.
              Collaborating with Professor August, I further expanded my skills by writing Python scripts for experimental
              analysis and innovation. My recent coursework involved in-depth work with C. I have tried my hand at many aspects
              of the CS field, including the Operating Systems, Deep Learning for Computer Vision, and Web Design ...
              This diverse exploration of computer science fuels my passion to continuously advance
              and explore new horizons in this ever-evolving field.<br></br> </p>
          </div>
          <div className="col-12" style={sliderStyle}>
            <Carousel responsive={responsive} infinite={true} className="owl-carousel owl-theme skill-slider">
              <div className="item" >
                <img src={java} alt="java" />
              </div>
              <div className="item">
                <img src={python} alt="python" />
              </div>
              <div className="item">
                <img src={c} alt="c" />
              </div>
              <div className="item">
                <img src={vim} alt="vim" />
              </div>
              <div className="item">
                <img src={vscode} alt="vscode" />
              </div>
              <div className="item">
                <img src={react} alt="react" />
              </div>
              <div className="item">
                <img src={npm} alt="npm" />
              </div>
              <div className="item">
                <img src={json} alt="json" />
              </div>
              <div className="item">
                <img src={docker} alt="docker" />
              </div>
              <div className="item">
                <img src={html} alt="html" />
              </div>
              <div className="item">
                <img src={js} alt="js" />
              </div>
            </Carousel>
          </div>
        </div>
      </div>
    </section>
  )
}

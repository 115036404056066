import { Container, Row, Col } from "react-bootstrap";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Banner = () => {
  const moveUpStyle = {
    marginBottom: '300px'
  };

  return (
    <section className="banner" id="home">
      <Container>
        <Row className="align-items-center">
          <Col xs={12} md={6} xl={5}></Col>
          <Col xs={12} md={6} xl={7}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__fadeIn" : ""} style={moveUpStyle}>
                  <h1>{`Hi! I'm Minchao Huang`}</h1>
                  <p>Friends usually call me Max. I am a Computer Science student at <a href="https://www.wisc.edu/" target="_blank">UW Madison</a>. 
                    I am a passionate and self-motivated person. I get a full GPA at school, and also, 
                    now I am interning at <a href="https://www.jisuanke.com/" target="_blank">Jisuanke</a>, a start up dedicated to revolutionizing Computer Science education for Chinese youth.
                    <br></br>My research interests are in software engineering, and my work focuses on prioritizing tests 
                    for improved runtime. I have had the invaluable opportunity to work with Prof. <a href="https://sites.utexas.edu/august/" target="_blank">August Shi</a> at <a href="https://www.utexas.edu/" target="_blank">UT Austin</a>. 
                    <br></br>I’m also quite interested in HCI and AI, and I’m looking for more research opportunities.</p>
                </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
